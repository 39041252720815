import { CircularProgress, Box } from "@mui/material";
import React from "react";

export const PageLoader = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <CircularProgress 
        size={50} 
        thickness={2} 
        color="primary"
      />
    </Box>
  );
};
