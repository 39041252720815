import { useAuth0 } from '@auth0/auth0-react';
import { Paper, Box, Grid, Typography, CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';

const ErrorPage = () => {
    const { loginWithRedirect } = useAuth0();
    
    useEffect(() => {
        const timer = setTimeout(() => {
            loginWithRedirect();
        }, 2000);

        return () => clearTimeout(timer);
    }, [loginWithRedirect]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <Paper elevation={3} sx={{ padding: 2, width: '100%', maxWidth: 650, borderRadius: '12px' }}>
                <Grid container spacing={1} sx={{ padding: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="body1">Please don't navigate by the browser address or refresh button. Use the navigation buttons provided on the page.</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress
                            size={36}
                            sx={{ marginLeft: 1 }}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
};

export default ErrorPage;