import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from './auth/roles';
import { PlaidProvider } from './context/plaid-context';
import { AuthenticationGuard } from './auth/authentication-guard';
import { AssistantProvider } from './context/assistant-context';
import { SnackbarProvider } from './context/snackbar-context';
import { PageLoader } from './components/page-loader';
import { ThemeProvider } from '@emotion/react';
import { HeaderProvider } from './context/header-context';
import { Box, CssBaseline } from '@mui/material';
import theme from './theme';
import ErrorBoundary from './context/error-boundary';
import Header from './components/header';

// Lazy-loaded pages
const AdminDashboard = lazy(() => import('./pages/admin-dashboard-page'));
const HelpPage = lazy(() => import('./pages/help-page'));
const NotFound = lazy(() => import('./pages/not-found-page'));
const Dashboard = lazy(() => import('./pages/dashboard-page'));
const LoginCallbackPage = lazy(() => import('./pages/login-callback-page'));
const PaymentPage = lazy(() => import('./pages/payment-page'));
const HouseholdersPage = lazy(() => import('./pages/householders-page'));
const EntitiesPage = lazy(() => import('./pages/entities-page'));
const GoalsPage = lazy(() => import('./pages/goals-page'));
const AccountsPage = lazy(() => import('./pages/accounts-page'));
const DocumentsPage = lazy(() => import('./pages/documents-page'));
const LiabilitiesPage = lazy(() => import('./pages/liabilities-page'));
const PlaidPage = lazy(() => import('./pages/plaid-page'));
const IdentityVerificationPage = lazy(() => import('./pages/idv-page'));
const SettingsPage = lazy(() => import('./pages/settings-page'));
const MultiItemLinkPage = lazy(() => import('./pages/plaid-multi-item-link-page'));
const AdvisorDashboard = lazy(() => import('./pages/advisor-dashboard'));
const InsurancePage = lazy(() => import('./pages/insurance-page'));
const LinkAccountsPage = lazy(() => import('./pages/link-accounts-page'));
const PortfolioManagementPage = lazy(() => import('./pages/portfolio-management-page'));
const AnalyticsPage = lazy(() => import('./pages/analytics-page'));
const ErrorPage = lazy(() => import('./pages/error-page'));
const FinancialPositionPage = lazy(() => import('./pages/financial-position-page'));
const RealEstatePage = lazy(() => import('./pages/real-estate-page'));
const RetirementPage = lazy(() => import('./pages/retirement-page'));
const BusinessPage = lazy(() => import('./pages/business-page'));
const VehiclesPage = lazy(() => import('./pages/vehicles-page'));
const PersonalPage = lazy(() => import('./pages/personal-page'));
const PythiaPage = lazy(() => import('./pages/pythia-page'));

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <PageLoader />
      </Box>
    );
  }

  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <PlaidProvider>
            <AssistantProvider>
              <HeaderProvider>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                  <CssBaseline />
                  <Header />
                  <Box sx={{ width: '100%' }}>
                    <Suspense fallback={<PageLoader />}>
                      <Routes>
                        {/* Public routes */}
                        <Route path="*" element={<NotFound />} />
                        <Route path="/" element={<PythiaPage />} />
                        <Route path="/login-callback/*" element={<LoginCallbackPage />} />

                        {/* Protected routes - role-based access */}
                        <Route path="/admin" element={<AuthenticationGuard component={AdminDashboard} requiredRoles={[ROLES.ADMIN]} />} />
                        <Route path="/plaid" element={<AuthenticationGuard component={PlaidPage} requiredRoles={[ROLES.ADMIN]} />} />
                        <Route path="/multi-item-link" element={<AuthenticationGuard component={MultiItemLinkPage} requiredRoles={[ROLES.ADMIN]} />} />
                        <Route path="/analytics" element={<AuthenticationGuard component={AnalyticsPage} requiredRoles={[ROLES.ADMIN]} />} />

                        <Route path="/advisor-dash" element={<AuthenticationGuard component={AdvisorDashboard} requiredRoles={[ROLES.ADVISOR, ROLES.ADMIN]} />} />

                        {/* Common routes accessible to multiple roles */}
                        {[
                          { path: "/settings", component: SettingsPage },
                          { path: "/help", component: HelpPage },
                          { path: "/payment", component: PaymentPage },
                          { path: "/position", component: FinancialPositionPage },
                          { path: "/retirement", component: RetirementPage },
                          { path: "/accounts", component: AccountsPage },
                          { path: "/liabilities", component: LiabilitiesPage },
                          { path: "/pm", component: PortfolioManagementPage },
                          { path: "/insurance", component: InsurancePage },
                          { path: "/re", component: RealEstatePage },
                          { path: "/documents", component: DocumentsPage },
                          { path: "/idv", component: IdentityVerificationPage },
                          { path: "/idv-callback", component: HouseholdersPage },
                          { path: "/goals", component: GoalsPage },
                          { path: "/entities", component: EntitiesPage },
                          { path: "/link-accounts", component: LinkAccountsPage },
                          { path: "/dashboard", component: Dashboard },
                          { path: "/personal", component: PersonalPage },
                          { path: "/business", component: BusinessPage },
                          { path: "/vehicles", component: VehiclesPage },
                          { path: "/not-found", component: NotFound },
                          { path: "/error", component: ErrorPage },
                        ].map(({ path, component }) => (
                          <Route
                            key={path}
                            path={path}
                            element={<AuthenticationGuard component={component} requiredRoles={[ROLES.CLIENT, ROLES.ADVISOR, ROLES.ADMIN]} />}
                          />
                        ))}
                      </Routes>
                    </Suspense>
                  </Box>
                </Box>
              </HeaderProvider>
            </AssistantProvider>
          </PlaidProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;