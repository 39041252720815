import { uploadFiles } from '../services/data.service';
import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  List, ListItem, ListItemText, Modal,
  Box, Typography, Button,
  ListItemSecondaryAction,
  IconButton, CircularProgress,
  Tooltip, Fab
} from '@mui/material';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useSnackbar } from '../context/snackbar-context';
import { useDropzone } from 'react-dropzone';
import { useAuth0 } from '@auth0/auth0-react';

function FileUploadButton() {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const { handleSubmit } = useForm();
  const [openModal, setOpenModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const { displayMessage } = useSnackbar();

  const onSubmit = async () => {
    const formData = new FormData();
    selectedFiles.forEach(file => {
      formData.append('files', file);
    });

    try {
      const accessToken = await getAccessTokenSilently();
      const { error } = await uploadFiles('upload', formData, accessToken);

      if (error) {
        throw new Error('Failed to upload files');
      }

      setOpenModal(false);
      setSelectedFiles([]);
      displayMessage('Uploaded successfully!', 'success');
    } catch (error) {
      console.log(error);
      displayMessage('Failed to upload files: ' + (error.response?.data?.detail || error.message), 'error');
    } finally {
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter(file => 
      ['text/csv', 'application/pdf', 'application/msword', 'text/plain', 'image/jpeg', 'image/png'].includes(file.type) && file.size < 10485760
    );
    
    if (filteredFiles.length !== acceptedFiles.length) {
      displayMessage('Only CSV, PDF, TXT, DOC, DOCX, JPEG and PNG files under 10MB are allowed', 'error');
    }

    setSelectedFiles(prevFiles => [...prevFiles, ...filteredFiles]);
  }, [displayMessage]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.docx', '.doc'],
      'text/plain': ['.txt'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png']
    }
  });
  
  const handleRemoveFiles = () => {
    setSelectedFiles([]);
  };

  return (
    <div>
        <Tooltip title="Upload Files">
          <Fab
            color="secondary"
            size="small"
            className='file-upload-button'
            sx={{ 
              zIndex: 1300,
              position: 'fixed',
              bottom: 16,
              right: 16
            }}
            onClick={() => setOpenModal(true)}
            >
                <UploadFileIcon />
            </Fab>
          </Tooltip>

      <Modal open={openModal} onClose={() => {
        setOpenModal(false);
        handleRemoveFiles();
      }}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, bgcolor: 'background.paper', p: 3, borderRadius: '12px' }}>
          <Typography variant="h6">Upload Files</Typography>
          <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '20px', textAlign: 'center', marginTop: '12px' }}>
            <input {...getInputProps()} />
            <Typography>Drag and drop files here, or click to select files</Typography>
            <Typography variant="caption">Only CSV, PDF, TXT, DOC, DOCX, JPEG and PNG files under 10MB are allowed</Typography>
          </div>
          <List>
            {selectedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText 
                  primary={file.name}
                  secondary={`${(file.size / 1024).toFixed(2)} KB`}
                />
                <ListItemSecondaryAction>
                  <IconButton color='primary' onClick={() => setSelectedFiles(selectedFiles.filter((_, i) => i !== index))}>
                    <RemoveCircleIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
          {loading ? (
            <Box display="flex" justifyContent="center" mt={2}>
              <CircularProgress />
            </Box>
          ) : (
            <Button color="secondary" onClick={handleSubmit(onSubmit)} disabled={selectedFiles.length === 0} variant="contained">
              Upload
            </Button>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default FileUploadButton;
