import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getData, postData } from '../services/data.service';
import { IconButton, Menu, MenuItem, List, ListItem, ListItemText, Button } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';

const NotificationsMenu = () => {
    const [notifications, setNotifications] = useState([]);
    const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    // Open and close notifications menu
    const handleNotificationsMenuOpen = (event) => {
        setNotificationsAnchorEl(event.currentTarget);
    };

    const handleNotificationsMenuClose = () => {
        setNotificationsAnchorEl(null);
    };

    // Fetch notifications (unread by default)
    const fetchNotifications = async (viewAll = false) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const { data } = await getData(`notifications${viewAll ? '?all=true' : ''}`, accessToken);
            console.log('Notifications:', data);
            // setNotifications(data.notifications);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    };

    // Mark all notifications as read
    const markAllAsRead = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            await postData('notifications/mark_all_as_read', {}, accessToken);
            console.log('All notifications marked as read');
            setNotifications(notifications.map(notification => ({ ...notification, read: true })));
        } catch (error) {
            console.error('Error marking notifications as read:', error);
        }
    };

    // Check for unread notifications
    const hasUnreadNotifications = notifications.some(notification => !notification.read);

    // Fetch unread notifications on component mount
    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <>
            <IconButton 
                className="notifications-button" // Added class
                color="inherit"
                onClick={handleNotificationsMenuOpen}
                >
                {hasUnreadNotifications ? <NotificationsActiveIcon /> : <NotificationsNoneIcon />}
            </IconButton>
            <Menu
                anchorEl={notificationsAnchorEl}
                open={Boolean(notificationsAnchorEl)}
                onClose={handleNotificationsMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <List>
                    {notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <ListItem key={notification.id} dense>
                                <ListItemText
                                    primary={notification.message}
                                    style={{ color: notification.read ? 'gray' : 'black' }}
                                />
                            </ListItem>
                        ))
                    ) : (
                        <ListItem>
                            <ListItemText primary="No new notifications" />
                        </ListItem>
                    )}
                </List>
                {notifications.length > 0 && (
                    <MenuItem onClick={() => { fetchNotifications(true); handleNotificationsMenuClose(); }}>
                        <Button fullWidth>View all</Button>
                    </MenuItem>
                )}
                {notifications.length > 0 && (
                    <MenuItem onClick={() => { markAllAsRead(); handleNotificationsMenuClose(); }}>
                        <Button fullWidth>Mark all as read</Button>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

export default NotificationsMenu;
