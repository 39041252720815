import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { PageLoader } from "../components/page-loader";
import ErrorPage from '../pages/error-page';

export const AuthenticationGuard = ({ component, requiredRoles = [] }) => {
  const { getAccessTokenSilently, user, isAuthenticated, isLoading: authLoading } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      if (isAuthenticated && user) {
        try {
          // Ensure the custom claim exists in the user object
          const userRoles = user['https://pythia.coroebuswm.com/api/v1/roles']?.data.map(role => role.name) || [];

          if (!userRoles) {
            throw new Error("Roles claim is not present in the user object.");
          }

          console.log('Auth guard user roles:', userRoles);

          setRoles(userRoles);
        } catch (error) {
          console.error("Error fetching roles:", error);
        } finally {
          setIsLoading(false); // Stop loading after roles are fetched
        }
      } else if (!isAuthenticated && !authLoading) {
        // If the user is not authenticated, handle it here (e.g., redirect to login)
        setIsLoading(false); // Stop loading even if the user is not authenticated
      }
    };

    fetchRoles();
  }, [getAccessTokenSilently, isAuthenticated, user, authLoading]);

  // If authentication or roles are still loading, show the loader
  if (authLoading || isLoading) {
    return <PageLoader />;
  }

  // Determine if the user has access based on their roles
  const hasAccess = requiredRoles.length === 0 || (Array.isArray(roles) && roles.some(role => requiredRoles.includes(role)));

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <PageLoader />,
  });

  return hasAccess ? <Component /> : <ErrorPage />;
};
