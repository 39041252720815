import React from 'react';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROLES } from '../auth/roles';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SourceIcon from '@mui/icons-material/Source';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { useHeader } from '../context/header-context';

const DrawerContent = ({ onClose }) => {
    const { roles } = useHeader();
    
    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={onClose}
            onKeyDown={onClose}
        >
            <List>
                {/* Role-specific items */}
                {roles.includes(ROLES.ADMIN) && (
                    <>
                        <ListItemButton component={Link} to="/admin">
                            <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                            <ListItemText primary="Admin" />
                        </ListItemButton>
                    </>
                )}
                {roles.includes(ROLES.ADVISOR) && (
                    <>
                        <ListItemButton component={Link} to="/advisor-dash">
                            <ListItemIcon><SupervisedUserCircleIcon /></ListItemIcon>
                            <ListItemText primary="Advisor Dashboard" />
                        </ListItemButton>
                    </>
                )}
                {/* Common items */}
                <ListItemButton component={Link} to="/dashboard" className='menu-item-dashboard'>
                    <ListItemIcon><DashboardIcon /></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItemButton>
                <ListItemButton component={Link} to="/documents" className='menu-item-documents'>
                    <ListItemIcon><SourceIcon /></ListItemIcon>
                    <ListItemText primary="Documents" />
                </ListItemButton>
                <ListItemButton component={Link} to="/position" className='menu-item-financial-position'>
                    <ListItemIcon><AccountBalanceWalletIcon /></ListItemIcon>
                    <ListItemText primary="Financial Position" />
                </ListItemButton>
                <Divider />
                {roles.includes(ROLES.ADMIN) && (
                    <>
                        <ListItemButton component={Link} to="/analytics">
                            <ListItemIcon><InsertChartIcon /></ListItemIcon>
                            <ListItemText primary="Analytics" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/plaid">
                            <ListItemIcon><AssuredWorkloadIcon /></ListItemIcon>
                            <ListItemText primary="Plaid Routes" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/multi-item-link">
                            <ListItemIcon><InsertLinkIcon /></ListItemIcon>
                            <ListItemText primary="Multi Item Link" />
                        </ListItemButton>
                        <Divider />
                        <ListItemButton component={Link} to="/payment">
                            <ListItemIcon><CurrencyExchangeIcon /></ListItemIcon>
                            <ListItemText primary="Payments" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/business">
                            <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
                            <ListItemText primary="Business" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/vehicles">
                            <ListItemIcon><DirectionsCarIcon /></ListItemIcon>
                            <ListItemText primary="Vehicles" />
                        </ListItemButton>
                        <ListItemButton component={Link} to="/personal">
                            <ListItemIcon><PersonOutlineIcon /></ListItemIcon>
                            <ListItemText primary="Personal" />
                        </ListItemButton>
                    </>
                )}
            </List>
        </Box>
    );
};

export default DrawerContent;
