import React, { useState, useRef, useEffect, useCallback } from 'react';
import {
  Box,
  TextField,
  IconButton,
  CircularProgress,
  Paper,
  Typography,
  Fab,
  Tooltip,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import ChatIcon from '@mui/icons-material/Chat';
import VisualizationModal from './visualization_modal';
import { useAuth0 } from '@auth0/auth0-react';
import { useAssistant } from '../context/assistant-context';
import { useSnackbar } from '../context/snackbar-context';
import { sendMessage } from '../services/data.service';

const ChatMessage = React.memo(({ message }) => (
  <Paper
    elevation={3}
    sx={{
      padding: '10px',
      marginBottom: '10px',
      backgroundColor: message.type === 'user' ? '#c3deea' : '#f9c5e0',
    }}
  >
    <Typography variant="body1">{message.text}</Typography>
  </Paper>
));

const ChatBox = React.memo(({ messages, chatBoxRef }) => (
  <Box ref={chatBoxRef} sx={{ flexGrow: 1, overflowY: 'auto', mb: 1 }}>
    {messages.map((msg, index) => (
      <ChatMessage key={index} message={msg} />
    ))}
  </Box>
));

const InputArea = React.memo(({ input, setInput, loading, handleSend, handleKeyDown }) => (
  <Box display="flex" alignItems="center" sx={{ mt: 'auto' }}>
    <TextField
      value={input}
      onChange={(e) => setInput(e.target.value)}
      onKeyDown={handleKeyDown} // Handle Enter key submission
      label="Ask something..."
      variant="outlined"
      fullWidth
      disabled={loading}
      sx={{ mr: 1 }}
    />
    <IconButton onClick={handleSend} color="primary" disabled={loading}>
      {loading ? <CircularProgress size={24} /> : <SendIcon />}
    </IconButton>
  </Box>
));

const Assistant = React.memo(() => {
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const { assistantMessages, addMessage } = useAssistant();
  const [loading, setLoading] = useState(false);
  const { displayMessage } = useSnackbar();
  const [visualizationData, setVisualizationData] = useState(null); // Add visualization state
  const [modalOpen, setModalOpen] = useState(false); // Control the modal state
  const chatBoxRef = useRef(null);

  // Auto-scroll the chat box when new messages arrive
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [assistantMessages]);

  // Handle sending a message
  const handleSend = useCallback(async () => {
    if (!input.trim()) return;
    setLoading(true);
  
    try {
      const token = await getAccessTokenSilently();
      const body = { message: input };
  
      addMessage(input, 'user');
      setInput('');
  
      const { data } = await sendMessage(body, token);
  
      // Extract and handle the response
      let responseText = 'I did not understand that. Could you please rephrase?';
  
      if (data?.result?.response_text) {
        responseText = data.result.response_text;
      }
      
      addMessage(responseText, 'assistant');
  
      // If visualization data is present, update state and open modal
      if (data?.result?.visualization) {
        setVisualizationData(data.result.visualization);
        setModalOpen(true);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      displayMessage('Failed to send message.', 'error');
    } finally {
      setLoading(false);
    }
  }, [input, getAccessTokenSilently, addMessage, displayMessage]);
  
  // Handle pressing Enter to send a message
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSend();
      }
    },
    [handleSend]
  );

  // Toggle the assistant open/close state
  const toggleAssistant = useCallback(() => setOpen((prevOpen) => !prevOpen), []);

  return (
    <>
      {open && (
        <Box
          sx={{
            position: 'fixed',
            bottom: 80,
            right: 16,
            width: 300,
            height: 400,
            backgroundColor: 'white',
            boxShadow: 3,
            borderRadius: 2,
            p: 2,
            zIndex: 1300,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ChatBox messages={assistantMessages} chatBoxRef={chatBoxRef} />
          <InputArea
            input={input}
            setInput={setInput}
            loading={loading}
            handleSend={handleSend}
            handleKeyDown={handleKeyDown}
          />
        </Box>
      )}

      {/* Visualization Modal */}
      <VisualizationModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        visualizationData={visualizationData}
      />

      {/* Floating Assistant Button */}
      <Tooltip title={open ? 'Close Assistant' : 'Open Assistant'}>
        <Fab
          color="secondary"
          aria-label="assistant"
          size="small"
          className="assistant"
          onClick={toggleAssistant}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 64,
            zIndex: 1300,
          }}
        >
          <ChatIcon />
        </Fab>
      </Tooltip>
    </>
  );
});

export default Assistant;