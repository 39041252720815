import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1887c1',
    },
    secondary: {
      main: '#ee5da1',
    },
    white: {
      main: '#fff',
    },
  },
  typography: {
    h5: {
      fontWeight: 600,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          borderRadius: '8px',
        },
      },
    },
  },
});

export default theme;
