import axios from "axios";

/**
 * Makes an HTTP request using Axios and handles errors consistently.
 * @param {Object} options - The configuration options for the HTTP request.
 * @param {Object} options.config - The Axios request configuration object.
 * @returns {Promise<{data: any, error: {message: string}|null}>} - The response data or an error message.
 */
export const callExternalApi = async (options) => {
  try {
    const response = await axios(options.config);
    const { data } = response;

    return {
      data,
      error: null,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Extract information from Axios error
      const { response } = error;
      let message = "HTTP request failed";

      if (response) {
        // Error response from server
        message = response.data?.message || response.statusText || "Unknown server error";
      } else if (error.message) {
        // General Axios error message
        message = error.message;
      }

      return {
        data: null,
        error: { message },
      };
    }

    // Handle non-Axios errors
    return {
      data: null,
      error: { message: error.message || "An unknown error occurred" },
    };
  }
};