import React, { createContext, useContext, useState } from 'react';

// Create the context
const AssistantContext = createContext();

// Custom hook to use the assistant context
export const useAssistant = () => useContext(AssistantContext);

// Assistant Provider Component
export const AssistantProvider = ({ children }) => {
  const [assistantMessages, setAssistantMessages] = useState([]);

  // Function to add messages to the history
  const addMessage = (message, type) => {
    setAssistantMessages((prevMessages) => [...prevMessages, { text: message, type }]);
  };

  return (
    <AssistantContext.Provider value={{ assistantMessages, addMessage }}>
      {children}
    </AssistantContext.Provider>
  );
};
