import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { usePlaidLink } from 'react-plaid-link';
import { postData } from '../services/data.service';
import { useNavigate } from 'react-router-dom';

const PlaidContext = createContext();

export const usePlaid = () => {
  const context = useContext(PlaidContext);
  if (!context) {
    throw new Error('usePlaid must be used within a PlaidProvider');
  }
  return context;
};

export const PlaidProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [linkToken, setLinkToken] = useState(null);
  const navigate = useNavigate();

  // Error handler for reusable error logging
  const handleError = (message, error) => {
    console.error(message, error);
  };

  // Fetch the Plaid link token
  const fetchLinkToken = useCallback(async (products, additionalConsentedProducts = [], optionalProducts = []) => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await postData('create_link_token', {
        products,
        additional_consented_products: additionalConsentedProducts,
        optional_products: optionalProducts,
      }, accessToken);
      setLinkToken(response.data.link_token);
    } catch (error) {
      handleError('Error fetching Plaid link token:', error);
    }
  }, [getAccessTokenSilently]);

  const resetLinkToken = useCallback(() => {
    setLinkToken(null);
  }, []);

  // Success handler for Plaid Link
  const onSuccess = useCallback(async (public_token, metadata) => {
    console.log('Plaid Link success:', public_token, metadata);
    // Set user is onboard to true
    const accessToken = await getAccessTokenSilently();
    await postData('onboard', {}, accessToken);
    
    navigate('/dashboard');
  }, [navigate, getAccessTokenSilently]);

  // Memoize the Plaid config to avoid recreating on every render
  const config = useMemo(() => ({
    onSuccess,
    onExit: (error, metadata) => {
      if (error) {
        handleError('Error during Plaid Link:', error);
        // redirect to error page or login-callback page
      }
      console.log('Plaid Link exit:', metadata);

    },
    onEvent: (eventName, metadata) => {
      console.log('Plaid Link event:', eventName, metadata);
    },
    token: linkToken,
  }), [onSuccess, linkToken]);

  const { open, ready, exit } = usePlaidLink(config);

  const value = useMemo(() => ({
    linkToken,
    fetchLinkToken,
    setLinkToken,
    resetLinkToken,
    open,
    ready,
    exit,
  }), [linkToken, fetchLinkToken, open, ready, exit, resetLinkToken]);

  return <PlaidContext.Provider value={value}>{children}</PlaidContext.Provider>;
};
