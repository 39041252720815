import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { LineChart, Line, PieChart, Pie, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const VisualizationModal = ({ open, handleClose, visualizationData }) => {
    const { visualization_type, chart_data = [], title = 'Visualization' } = visualizationData || {};
  
    const saveVisualization = (chartData, visualizationType) => {
      console.log('Saving visualization:', { chartData, visualizationType });
    };
  
    const renderChart = () => {
      if (!visualization_type) {
        return <Typography>No data available for visualization</Typography>;
      }
  
      switch (visualization_type) {
        case 'line_chart':
          return (
            <LineChart width={600} height={300} data={chart_data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="value" stroke="#ee5da1" />
            </LineChart>
          );
        case 'pie_chart':
          return (
            <PieChart width={400} height={400}>
              <Pie dataKey="value" data={chart_data} cx={200} cy={200} outerRadius={80} fill="#ee5da1" label />
              <Tooltip />
            </PieChart>
          );
        case 'bar_chart':
          return (
            <BarChart width={600} height={300} data={chart_data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="value" fill="#1887c1" />
            </BarChart>
          );
        default:
          return <Typography>No data available for visualization</Typography>;
      }
    };
  
    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 650,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 1,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ my: 2 }}>{renderChart()}</Box>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            <Button variant="outlined" onClick={handleClose} sx={{ mr: 1 }}>
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={() => saveVisualization(chart_data, visualization_type)}>
              Save Visualization
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };
  
  export default VisualizationModal;