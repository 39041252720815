import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

/**
 * Handles API errors and provides a consistent error message.
 * @param {any} error - The error object returned from the API call.
 * @returns {string} - A formatted error message.
 */
const handleApiError = (error) => {
  if (error?.response) {
    // API responded with a status code outside 2xx
    console.error("API Error Response:", error.response);
    return error.response.data?.message || "An error occurred with the API.";
  } else if (error?.request) {
    // No response received
    console.error("API No Response:", error.request);
    return "No response received from the API.";
  } else {
    // Error setting up the request
    console.error("API Request Error:", error.message);
    return error.message || "An unknown error occurred.";
  }
};

/**
 * Makes a generic API request.
 * @param {string} endpoint - The API endpoint (e.g., "tasks").
 * @param {string} method - The HTTP method (e.g., "GET", "POST").
 * @param {Object|FormData|null} body - The request payload.
 * @param {string} accessToken - The JWT token for authorization.
 * @param {string} responseType - The expected response type ('json', 'blob', etc.).
 * @param {Object} additionalHeaders - Additional headers to include.
 * @returns {Promise<{data: any, error: string|null}>} - The response data or error message.
 */
const makeApiRequest = async (
  endpoint,
  method,
  body = null,
  accessToken,
  responseType = "json",
  additionalHeaders = {}
) => {
  const isFormData = body instanceof FormData;

  const config = {
    url: `${apiServerUrl}${endpoint}`,
    method,
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      ...(isFormData ? {} : { "Content-Type": "application/json" }), // Avoid setting content-type for FormData
      ...additionalHeaders,
    },
    data: body || undefined,
    responseType,
  };

  try {
    const { data } = await callExternalApi({ config });
    return { data, error: null };
  } catch (error) {
    const errorMessage = handleApiError(error);
    return { data: null, error: errorMessage };
  }
};

/**
 * Sends a message to the assistant through the backend API.
 * @param {Object} body - The request payload, including the message.
 * @param {string} accessToken - The JWT token for authorization.
 * @returns {Promise<{data: any, error: string|null}>} - The assistant's response or error message.
 */
export const sendMessage = async (body, accessToken) => {
  return makeApiRequest("assistant/run", "POST", body, accessToken);
};

/**
 * Fetches data from the backend API.
 * @param {string} endpoint - The API endpoint to fetch data from.
 * @param {string} accessToken - The JWT token for authorization.
 * @param {string} responseType - The expected response type ('json', 'blob', etc.).
 * @returns {Promise<{data: any, error: string|null}>} - The fetched data or error message.
 */
export const getData = (endpoint, accessToken, responseType = "json") => {
  return makeApiRequest(endpoint, "GET", null, accessToken, responseType);
};

/**
 * Uploads files to the backend API.
 * @param {string} endpoint - The API endpoint to upload files to.
 * @param {FormData} formData - The form data containing files to upload.
 * @param {string} accessToken - The JWT token for authorization.
 * @returns {Promise<{data: any, error: string|null}>} - The response or error message.
 */
export const uploadFiles = (endpoint, formData, accessToken) => {
  return makeApiRequest(endpoint, "POST", formData, accessToken);
};

/**
 * Submits data to the backend API.
 * @param {string} endpoint - The API endpoint to submit data to.
 * @param {Object|Array|FormData} body - The request payload.
 * @param {string} accessToken - The JWT token for authorization.
 * @param {string} responseType - The expected response type ('json', 'blob', etc.).
 * @returns {Promise<{data: any, error: string|null}>} - The response data or error message.
 */
export const postData = (endpoint, body, accessToken, responseType = "json") => {
  return makeApiRequest(endpoint, "POST", body, accessToken, responseType);
};

/**
 * Updates data at the backend API.
 * @param {string} endpoint - The API endpoint to update data at.
 * @param {Object|Array|FormData} body - The data to update.
 * @param {string} accessToken - The JWT token for authorization.
 * @param {string} responseType - The expected response type ('json', 'blob', etc.).
 * @returns {Promise<{data: any, error: string|null}>} - The updated data or error message.
 */
export const putData = (endpoint, body, accessToken, responseType = "json") => {
  return makeApiRequest(endpoint, "PUT", body, accessToken, responseType);
};

/**
 * Deletes data from the backend API.
 * @param {string} endpoint - The API endpoint to delete data from.
 * @param {Object|Array|string} body - The data to delete.
 * @param {string} accessToken - The JWT token for authorization.
 * @param {string} responseType - The expected response type ('json', 'blob', etc.).
 * @returns {Promise<{data: any, error: string|null}>} - The response or error message.
 */
export const deleteData = (endpoint, body, accessToken, responseType = "json") => {
  return makeApiRequest(endpoint, "DELETE", body, accessToken, responseType);
};

// Add a specific function for fetching file data as a blob
export const getFileData = (endpoint, accessToken) => {
  return makeApiRequest(endpoint, "GET", null, accessToken, 'blob');
};