import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer } from '@mui/material';
import NotificationsMenu from './notifications-menu';
import ProfileMenu from './profile-menu';
import DrawerContent from './drawer-content';
import Assistant from './assistant';
import FileUploadButton from './file-upload-button';
import pytAxe from '../assets/logo512.png';
import cwmLogo from '../assets/cwmLogo.png';
import { useHeader } from '../context/header-context';

const Header = React.memo(() => {
  const { isAuthenticated, loginWithRedirect } = useHeader();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleLogoClick = () => {
    window.location.href = 'https://coroebuswm.com';
  };

  // Open and close the drawer
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Header for non-authenticated users
  const renderNonAuthenticatedHeader = () => (
    <AppBar position="fixed" color="white" elevation={0}>
      <Toolbar sx={{ justifyContent: 'space-between', maxHeight: 60 }}>
        {/* Logo for non-authenticated users */}
        <IconButton onClick={() => handleLogoClick()}>
          <img src={cwmLogo} alt="Coroebus Wealth Management" style={{ maxHeight: 50 }} />
        </IconButton>
        {/* Login button */}
        <IconButton onClick={() => loginWithRedirect()}>
          <img src={pytAxe} alt="PYTHIA" style={{ height: 45 }} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );

  // Header for authenticated users
  const renderAuthenticatedHeader = () => (
    <>
      <AppBar position="fixed" color="transparent" elevation={0}>
        <Toolbar sx={{ justifyContent: 'space-between', maxHeight: 35 }}>
          {/* Logo to open the drawer (disabled until user is onboard) */}
          <IconButton
            color="inherit"
            onClick={toggleDrawer(true)}
            className='logo-menu'
          >
            <img src={pytAxe} alt="PYTHIA" style={{ height: 45 }} />
          </IconButton>

          {/* Right-aligned icons */}
          <Box sx={{ marginLeft: 'auto' }}>
            <NotificationsMenu />
            <ProfileMenu />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for menu */}
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <DrawerContent onClose={toggleDrawer(false)} />
      </Drawer>

      {/* Assistant and file upload button */}
      <Assistant />
      <FileUploadButton />
    </>
  );

  return (
    <div>
      {/* Conditional rendering based on authentication status */}
      {!isAuthenticated ? renderNonAuthenticatedHeader() : renderAuthenticatedHeader()}
    </div>
  );
});

export default Header;
