// path: pythia-frontend/src/index.js

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from "./auth/auth0-provider-with-navigate";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithNavigate>
          <App />
    </Auth0ProviderWithNavigate>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
