import React, { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { getData } from '../services/data.service'; // Assuming a generic getData service for API requests

const HeaderContext = createContext();

export const useHeader = () => useContext(HeaderContext);

export const HeaderProvider = ({ children }) => {
  const { user, isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  const [notifications, setNotifications] = useState([]);
  const [roles, setRoles] = useState([]);

  // Fetch notifications on mount if the user is authenticated
  useEffect(() => {
    if (isAuthenticated) {
        // Function to fetch notifications from the backend
      const fetchNotifications = async () => {
        try {
          const token = await getAccessTokenSilently();
          const notificationsData = await getData('notifications', token);
          setNotifications(notificationsData);

          const userRoles = user['https://pythia.coroebuswm.com/api/v1/roles']?.data.map(role => role.name) || [];
          console.log('Header context user roles:', userRoles);
          setRoles(userRoles);
        } catch (error) {
          console.error('Failed to fetch notifications:', error);
        }
      };

      fetchNotifications();
    }
  }, [isAuthenticated, getAccessTokenSilently, user]);

  // Memoize the context value
  const value = useMemo(() => ({
    isAuthenticated,
    loginWithRedirect,
    notifications,
    logout,
    roles,
  //  fetchNotifications, // Provide the fetchNotifications function for manual refresh
  }), [isAuthenticated, loginWithRedirect, logout, notifications, roles]);

  return (
    <HeaderContext.Provider value={value}>
      {children}
    </HeaderContext.Provider>
  );
};
