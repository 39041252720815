import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Link } from 'react-router-dom';
import { useHeader } from '../context/header-context';

const ProfileMenu = () => {
    const { logout } = useHeader();
    const [anchorEl, setAnchorEl] = useState(null);

    // Open and close profile menu
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                edge="end"
                color="inherit"
                className="profile-button" // Added class
                onClick={handleMenuOpen}
                >
                <PersonOutlineIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem onClick={handleMenuClose}>
                    <Link to="/settings" style={{ textDecoration: 'none', color: 'inherit' }}>Settings</Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                    <Link to="/help" style={{ textDecoration: 'none', color: 'inherit' }}>Help</Link>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleMenuClose();
                    logout({ logoutParams: { returnTo: window.location.origin } });
                }}>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );
};

export default ProfileMenu;
